import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import striptags from "striptags"
import NewFlag from "../atoms/NewFlag"
import useDateCheck from "../../hooks/useDateCheck"
//import RatingStars from "../atoms/RatingStars"

const ProductCard = ({ item }) => {
  const { isNewFlag, isNewStart, isNewEnd } = item
  const shouldDisplayNewFlag = useDateCheck(isNewStart, isNewEnd, isNewFlag)

  if (item.image === "undefined") {
    item.image = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
  }
  let borderColor = "border-gray-100"
  if (item.border_color) {
    borderColor = item.border_color
  }

  /*  isNewFlag && console.log(isNewStart, isNewEnd) */
  return (
    <Link className="flex flex-col w-full group" link={item.link}>
      <div
        className={`relative border aspect-square block w-full ${borderColor}`}
      >
        <div className="w-full relative overflow-hidden">
          {shouldDisplayNewFlag && <NewFlag type="card" />}
          <Image
            className="w-full h-auto min-h-full"
            image={item.image}
            aspectRatio="1by1"
            fitIn="true"
            fillColor="ffffff"
          />
        </div>
      </div>
      <div
        className={`flex-1 p-4 -mt-px border ${borderColor} flex flex-col justify-between`}
      >
        <div>
          <span className="font-bold group-hover:text-primary hyphens-auto">
            {item.headline}
          </span>
          <p className="mt-2 prose-sm ">{item.text}</p>
        </div>
        {item.subline && (
          <div className="flex flex-row justify-between">
            <span className="text-xs ">{item.subline}</span>
          </div>
        )}
        {item.description?.value?.Formatted && (
          <div className="flex flex-row justify-between">
            <span className="text-sm line-clamp-3">
              {striptags(
                item.description.value.Formatted.replace(
                  /<\/li><li>/g,
                  "</li> <li>"
                ).replace(/&#160;/g, " ")
              )}
            </span>
          </div>
        )}

        {item.subline_html && (
          <div className="block">
            {item.subline_html_label && (
              <span className="text-sm">{item.subline_html_label}</span>
            )}
            <span
              className={`text-sm ${item.subline_html_classNames}`}
              dangerouslySetInnerHTML={{ __html: item.subline_html }}
            ></span>
          </div>
        )}
      </div>
    </Link>
  )
}

export default ProductCard
